import {Option} from "ts-option";
import {
	Button, Checkbox,
	CircularProgress, FormControl, FormControlLabel, FormHelperText,
	Grid, InputLabel,
	makeStyles, MenuItem,
	Modal, Select,
	Typography
} from "@material-ui/core";
import {Field, Form, Formik, FormikHelpers, FormikProps} from "formik";
import {defaultStyles} from "../../styles/defaultStyles";
import {apiInstance} from "../../api/Api";
import FormTextField from "../common/FormTextField";
import * as Yup from "yup";
import GinDocument from "../../models/GinDocument";
import React, {useState} from "react";
import Technology from "../../models/Technology";
import {KeyboardDatePicker, MaterialUiPickersDate} from "@material-ui/pickers";
import {DEFAULT_DATE_FORMAT} from "../../common/Constants";
import DateUtils from "../../common/DateUtils";

const validationSchema = Yup.object().shape({
	description: Yup.string()
		.required('Description is required'),
});

const validationSchemaCreate = Yup.object().shape({
	description: Yup.string()
		.required('Description is required'),
	quantity: Yup.number()
		.when("technologyId", {
			is: "select",
			then: Yup.number(),
			otherwise: Yup.number()
				.required('Quantity is required')
				.integer('Quantity have to be integer')
				.positive('Quantity have to be positive number')
		})
});

interface IProps {
	isOpen: boolean,
	ginDocument: Option<GinDocument>,
	onClose: () => void,
	technologies: Technology[],
}

interface GinFormValues {
	description: string,
	technologyId: string,
	quantity: string,
	documentDate: Date,
	internalUse: boolean,
}

const useStyles = makeStyles(defaultStyles);

const GinDocumentsModal: React.FC<IProps> = (props: IProps) => {
	const {isOpen, ginDocument, onClose, technologies} = props;
	const [isSubmitting, setSubmitting] = useState(false);

	const classes = useStyles({});

	const initialValues: GinFormValues = props.ginDocument.map(doc => {
		return ({
			description: doc.description,
			technologyId: "select",
			quantity: "",
			documentDate: doc.documentDate.toDate(),
			internalUse: doc.internalUse,
		});
	}).getOrElseValue({
		description: "",
		technologyId: "select",
		quantity: "",
		documentDate: new Date(),
		internalUse: false
	});

	const createTechnologyItems = (technologyId: string, ginDocumentId: string, quantity: number) => {
		return apiInstance.technologiesApi.fetchTechnologyItems(technologyId).then(items => {
			const partStates: GinPartStateApiData[] = items.map(item => {
				return (
					{
						partId: item.partId,
						quantity: item.quantity * quantity,
						typeId: item.typeId.isDefined ? item.typeId.get : null,
						fromTechnology: true,
					}
				);
			});
			return apiInstance.ginDocumentApi.postGinItems(ginDocumentId, partStates)
		})
	};

	const onSubmitFrom = (values: GinFormValues, actions: FormikHelpers<GinFormValues>) => {
		const submitAction = ginDocument.isDefined ?
			apiInstance.ginDocumentApi.putGinDocument(ginDocument.get.id, values.description,
				DateUtils.toUTCDate(values.documentDate), values.internalUse) :
			apiInstance.ginDocumentApi.postGinDocument(values.description, values.technologyId,
				values.quantity, DateUtils.toUTCDate(values.documentDate), values.internalUse);
		// TODO Handle errors nicely
		submitAction.then((newGinDocument) => {
			if (ginDocument.isEmpty && values.technologyId !== "select") {
				createTechnologyItems(newGinDocument.technologyId.get, newGinDocument.id,
					parseInt(values.quantity)).then(() => {
					setSubmitting(false);
					actions.resetForm();
					onClose();
				}).catch((err) => {
					console.error(err) // TODO Handle errors nicely
				})
			}
			setSubmitting(false);
			actions.resetForm();
			onClose();
		}).catch((err) => {
			setSubmitting(false);
			console.error(err)
		})
	};

	const validateAndCreateGin = (values: GinFormValues, actions: FormikHelpers<GinFormValues>) => {
		setSubmitting(true);
		if (values.technologyId !== "select") {
			apiInstance.technologiesApi.validateTechnologyId(values.technologyId,
				parseFloat(values.quantity)).then(isValid => {
				if (isValid) {
					onSubmitFrom(values, actions)
				}
				else {
					alert("Not enough element to create GIN Document");
					setSubmitting(false)
				}
			}).catch(err => console.error(err))
		}
		else {
			onSubmitFrom(values, actions)
		}
	};

	return (
		<Modal
			open={isOpen}
			onClose={() => onClose()}
			style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
			<div className={classes.wideModal}>
				<Formik
					initialValues={initialValues}
					enableReinitialize={true}
					validateOnBlur={true}
					validateOnChange={false}
					validationSchema={ginDocument.isDefined ? validationSchema : validationSchemaCreate}
					onSubmit={validateAndCreateGin}>
					{(formikProps: FormikProps<GinFormValues>) => (
						<Form noValidate={true}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Typography variant={"h6"}>{
										ginDocument.isDefined ?
											"Update GIN Document" :
											"Create New GIN Document"
									}</Typography>
									<Field
										id="description"
										label="Description"
										name="description"
										required={true}
										error={formikProps.errors.description && formikProps.touched.description}
										helperText={formikProps.errors.description &&
										formikProps.touched.description ?
											formikProps.errors.description : ""}
										as={FormTextField}
									/>
								</Grid>
								<Grid item xs={8}>
									<KeyboardDatePicker
										autoOk={true}
										disableToolbar
										inputVariant="outlined"
										required={true}
										fullWidth={true}
										maxDate={new Date()}
										format={DEFAULT_DATE_FORMAT}
										margin="dense"
										id="date-picker-inline"
										label="Document Date"
										value={formikProps.values.documentDate}
										onChange={(date: MaterialUiPickersDate) => {
											formikProps.setFieldValue("documentDate", date)
										}}
										KeyboardButtonProps={{
											'aria-label': 'change date',
										}}
									/>
								</Grid>
								<Grid item xs={4}>
									<FormControlLabel style={{marginTop: 8}}
													  control={
														  <Field name={"internalUse"}
																 color={"primary"}
																 onChange={() => {
																	 formikProps.setFieldValue("internalUse",
																		 !formikProps.values.internalUse);
																	 if (!formikProps.values.internalUse) {
																		 formikProps.setFieldValue("description", "Internal Use");
																		 formikProps.setFieldValue("technologyId", "select");
																		 formikProps.setFieldValue("quantity", "")
																	 } else {
																		 formikProps.setFieldValue("description", "");
																	 }
																 }}
																 as={Checkbox}/>
													  }
													  label="Internal Use"
									/>
								</Grid>
								{ginDocument.isEmpty &&
								<>
									<Grid item xs={8}>
										<FormControl variant={"outlined"} fullWidth={true} style={{marginTop: 8}}
													 error={Boolean(
														 formikProps.errors.technologyId &&
														 formikProps.touched.technologyId)}>
											<InputLabel>Select Technology</InputLabel>
											<Field name={"technologyId"} autoWidth={true} labelWidth={130} as={Select}
												   margin={"dense"} required={true}
												   onChange={(evt: any) => {
													   let technologyName: string = "";
													   if (evt.target.value !== "select") {
														   technologyName = technologies.filter(
															   tech => tech.id === evt.target.value)[0].name;
													   }
													   formikProps.setFieldValue("description", technologyName);
													   formikProps.setFieldValue("technologyId", evt.target.value)
												   }}
												   disabled={formikProps.values.internalUse}>
												<MenuItem value={"select"}>Select Technology</MenuItem>
												{technologies.sort(
													(a, b) => a.name.localeCompare(b.name)).map(
													part => <MenuItem
														value={part.id} key={part.id}>{part.name}</MenuItem>)}
											</Field>
											{formikProps.errors.technologyId && formikProps.touched.technologyId &&
											<FormHelperText>{formikProps.errors.technologyId}</FormHelperText>
											}
										</FormControl>
									</Grid>
									<Grid item xs={4}>
										<Field
											id="quantity"
											label="Quantity"
											name="quantity"
											required={true}
											disabled={formikProps.values.technologyId === "select" || formikProps.values.internalUse}
											error={formikProps.errors.quantity && formikProps.touched.quantity}
											helperText={formikProps.errors.quantity &&
											formikProps.touched.quantity ?
												formikProps.errors.quantity : ""}
											as={FormTextField}
										/>
									</Grid>
								</>
								}
								<Grid item xs={12}>
									<div className={classes.modalButtonsRow}>
										<Button onClick={() => onClose()}>Close</Button>
										<Button type="submit" color={"primary"} variant={"contained"}>
											{isSubmitting ? <CircularProgress style={{color: "white"}} size={24}/> :
												ginDocument.isDefined ? "Update" : "Create"}
										</Button>
									</div>
								</Grid>
							</Grid>
						</Form>
					)}
				</Formik>
			</div>
		</Modal>
	);
};

export default GinDocumentsModal

export interface GinPartStateApiData {
	partId: string,
	typeId: string | null,
	quantity: number,
	fromTechnology: boolean
}